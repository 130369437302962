import * as React from "react";

const FoodIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
    className={props.className}
    viewBox="0 0 462 475"
    {...props}
  >
    <path
      d="M380.73 400.595s28.649 7.723 42.391 139.194c-9.15 24.37-14.309 23.917-14.309 41.843v130.631c0 7.07-6.292 12.81-14.041 12.81-7.75 0-14.041-5.74-14.041-12.81V400.595Zm87.541 102.607c-14.966-6.105-26.044-27.794-26.044-53.581 0-30.52 15.517-55.298 34.629-55.298 19.113 0 34.63 24.778 34.63 55.298 0 26.513-11.711 48.693-27.316 54.059l7.528 197.365h-.01c.007.275.01.551.01.829 0 12.804-6.94 23.199-15.487 23.199s-15.486-10.395-15.486-23.199c0-.278.003-.554.009-.829h-.009l7.546-197.843Z"
      style={{fill:"white"}}
      transform="translate(-162.721 -322.206)"
    />
    <path
      d="M291.999 469.42c-2.705-19.951 13.53-73.06 13.53-73.06h3.036a2.99 2.99 0 0 0-.19.776c-.764 6.929.421 31.532 1.345 43.758a28.841 28.841 0 0 1 4.653-2.121c.895-12.753 1.913-35.088 1.191-41.637a2.99 2.99 0 0 0-.19-.776h4.694a2.95 2.95 0 0 0-.19.776c-.696 6.308.223 27.264 1.091 40.194a27.315 27.315 0 0 1 5.006 0c.868-12.93 1.787-33.886 1.092-40.194a2.951 2.951 0 0 0-.191-.776h4.694a2.99 2.99 0 0 0-.19.776c-.722 6.549.296 28.884 1.191 41.637a28.841 28.841 0 0 1 4.653 2.121c.925-12.226 2.109-36.829 1.345-43.758a2.99 2.99 0 0 0-.19-.776h3.036s16.19 53.129 13.542 73.155c.193 1.665.293 3.365.293 5.093 0 17.352-10.068 31.96-23.699 36.171l7.118 186.61c.19 1.451.29 2.951.29 4.485 0 12.804-6.94 23.199-15.487 23.199s-15.487-10.395-15.487-23.199c0-.278.004-.554.01-.829h-.16l7.261-190.36c-13.48-4.329-23.401-18.851-23.401-36.077 0-1.761.103-3.493.304-5.188Z"
      style={{fill:"white"}}
  
      transform="translate(-178.638 -322.206)"
    />
    <path
      d="M442.227 354.489c-6.223 0-11.275-3.466-11.275-7.735s5.052-7.735 11.275-7.735h143.538c6.227 0 11.275 3.463 11.275 7.735v424.135c0 4.272-5.048 7.735-11.275 7.735H442.227c-6.223 0-11.275-3.466-11.275-7.735 0-4.27 5.052-7.736 11.275-7.736H574.49V354.489H442.227Z"
      transform="matrix(.74126 0 0 1.08048 19.261 -366.302)"
    />
    <path
      d="M442.227 339.019c-6.223 0-11.275 3.466-11.275 7.735s5.052 7.735 11.275 7.735H574.49v408.664H442.227c-6.223 0-11.275 3.466-11.275 7.736 0 4.269 5.052 7.735 11.275 7.735h143.538c6.227 0 11.275-3.463 11.275-7.735V346.754c0-4.272-5.048-7.735-11.275-7.735H442.227Z"
      style={{}}
      transform="matrix(-.74126 0 0 1.08048 442.561 -366.302)"
    />
  </svg>
);

export default FoodIcon;
