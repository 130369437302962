import React from "react";
import { Link, useRouteError } from "react-router-dom";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import "./ErrorPage.scss";

const ErrorPage = () => {
  const error = useRouteError();
  console.log(error);

  return (
    <>
      <Header />
      <div id="error-page">
        <h1>Oops!</h1>
        <p>Sorry, an unexpected error has occurred.</p>
        <p>
          <i>
            <span style={{ color: "red" }}>{error.status}</span>{" "}
            {error.statusText || error.message}
          </i>
        </p>

        <Link to={`/`}>
          <button type="button" class="btn btn-dark error-button">
            Zurück zur Startseite
          </button>
        </Link>
      </div>
      <Footer />
    </>
  );
};

export default ErrorPage;
