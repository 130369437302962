import React, { useState, useMemo, useCallback } from "react";
import { Container, Row, Col, Image, Modal } from "react-bootstrap";

const ImageGallery = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const images = useMemo(
    () => [
      // Hier kannst du die URLs deiner Bilder einfügen
      "https://i.natgeofe.com/n/548467d8-c5f1-4551-9f58-6817a8d2c45e/NationalGeographic_2572187_square.jpg",
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTsNwGexu0XUM-SKCyzjlIlRwR79a6yOC0nTwndbuWVOy-AkqIlh8k99SAqO2jwgF1Phf8&usqp=CAU",
      "https://live-production.wcms.abc-cdn.net.au/d7ae378593516714c2197f3f4f98b8c5?impolicy=wcms_crop_resize&cropH=3333&cropW=5000&xPos=0&yPos=0&width=862&height=575",
      "https://i.natgeofe.com/n/548467d8-c5f1-4551-9f58-6817a8d2c45e/NationalGeographic_2572187_square.jpg",
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTsNwGexu0XUM-SKCyzjlIlRwR79a6yOC0nTwndbuWVOy-AkqIlh8k99SAqO2jwgF1Phf8&usqp=CAU",
      "https://live-production.wcms.abc-cdn.net.au/d7ae378593516714c2197f3f4f98b8c5?impolicy=wcms_crop_resize&cropH=3333&cropW=5000&xPos=0&yPos=0&width=862&height=575",
      "https://i.natgeofe.com/n/548467d8-c5f1-4551-9f58-6817a8d2c45e/NationalGeographic_2572187_square.jpg",
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTsNwGexu0XUM-SKCyzjlIlRwR79a6yOC0nTwndbuWVOy-AkqIlh8k99SAqO2jwgF1Phf8&usqp=CAU",
      "https://live-production.wcms.abc-cdn.net.au/d7ae378593516714c2197f3f4f98b8c5?impolicy=wcms_crop_resize&cropH=3333&cropW=5000&xPos=0&yPos=0&width=862&height=575",
      "https://i.natgeofe.com/n/548467d8-c5f1-4551-9f58-6817a8d2c45e/NationalGeographic_2572187_square.jpg",
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTsNwGexu0XUM-SKCyzjlIlRwR79a6yOC0nTwndbuWVOy-AkqIlh8k99SAqO2jwgF1Phf8&usqp=CAU",
      "https://live-production.wcms.abc-cdn.net.au/d7ae378593516714c2197f3f4f98b8c5?impolicy=wcms_crop_resize&cropH=3333&cropW=5000&xPos=0&yPos=0&width=862&height=575",
      "https://i.natgeofe.com/n/548467d8-c5f1-4551-9f58-6817a8d2c45e/NationalGeographic_2572187_square.jpg",
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTsNwGexu0XUM-SKCyzjlIlRwR79a6yOC0nTwndbuWVOy-AkqIlh8k99SAqO2jwgF1Phf8&usqp=CAU",
      "https://live-production.wcms.abc-cdn.net.au/d7ae378593516714c2197f3f4f98b8c5?impolicy=wcms_crop_resize&cropH=3333&cropW=5000&xPos=0&yPos=0&width=862&height=575",
      "https://i.natgeofe.com/n/548467d8-c5f1-4551-9f58-6817a8d2c45e/NationalGeographic_2572187_square.jpg",
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTsNwGexu0XUM-SKCyzjlIlRwR79a6yOC0nTwndbuWVOy-AkqIlh8k99SAqO2jwgF1Phf8&usqp=CAU",
      "https://live-production.wcms.abc-cdn.net.au/d7ae378593516714c2197f3f4f98b8c5?impolicy=wcms_crop_resize&cropH=3333&cropW=5000&xPos=0&yPos=0&width=862&height=575",
      // Weitere Bilder...
    ],
    []
  );

  const handleImageClick = useCallback((image) => {
    setSelectedImage(image);
    setShowModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setSelectedImage(null);
    setShowModal(false);
  }, []);

  return (
    <Container>
      <Row xs={2} md={4} style={{ justifyContent: "center", marginTop: 15 }}>
        {images.map((image, index) => (
          <Col
            key={index}
            onClick={() => handleImageClick(image)}
            style={{ margin: index % 2 ? "0 20px 0 10px" : "0 10px 0 20px" }}
          >
            <Image
              src={image}
              thumbnail
              style={{ objectFit: "cover", height: "100%" }}
            />
          </Col>
        ))}
      </Row>
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Body style={{ padding: "5px" }}>
          {selectedImage && (
            <Image src={selectedImage} fluid onClick={handleCloseModal} />
          )}
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default React.memo(ImageGallery);
