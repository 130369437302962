import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

const RootLayout = () => {
  return (
    <div className="root-layout">
      <Header />

      <Suspense fallback={<LoadingSpinner />}>
        <Outlet />
      </Suspense>

      <Footer />
    </div>
  );
};

export default RootLayout;
