import "./Header.scss";
import Logo from "../../files/Logo.png";

import React, { Component } from "react";
import Wrapper from "../helpers/Wrapper";
import Navigation from "./Navigation";
import { Link } from "react-router-dom";

export class Header extends Component {
  render() {
    return (
      <header id="header__section">
        <Wrapper>
          <div className="row header__row navigation">
            <div className="logo col-lg-6 col-xs-8">
              <Link to="/">
                <img
                  alt="logo Dönerspieß mit Schrift Döner Ecke"
                  src={Logo}
                ></img>
              </Link>
            </div>
            <Navigation />
          </div>
        </Wrapper>
      </header>
    );
  }
}

export default Header;
