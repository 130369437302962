import React from "react";
import "./Navigation.scss";
import { Link, NavLink } from "react-router-dom";
import FoodIcon from "../Icons/FoodIcon";
import foodImage from "../../files/food.png";

const Navigation = () => {
  return (
    <>
      <nav className="navigation-list col-lg-6">
        <NavLink to="/speisekarte">
          <FoodIcon
            className="food_icon_1 col-sm-3"
            inner={"food_icon"}
          ></FoodIcon>

          <li>SPEISEKARTE</li>
        </NavLink>
      </nav>

      <nav>
        <span>
          <Link to="/speisekarte">
            <img src={foodImage} className="food_icon_2 col-sm-3" />
          </Link>
        </span>
      </nav>
    </>
  );
};

export default Navigation;
