import React from "react";
import { Button, Modal } from "react-bootstrap";

const FoodlistModal = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-wrapper"
    >
      <Modal.Header className="modal-header" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <div>
            <i className="bi bi-info-square allergene_icon"></i>
            <h3>Allergene und Zusatzstoffe</h3>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table className="info_table">
          <thead>
            <tr>
              <th>Allergene</th>
              <th>Bedeutung</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>A</td>
              <td>Glutenhaltiges Getreide</td>
            </tr>
            <tr>
              <td>B</td>
              <td>Krebstiere</td>
            </tr>
            <tr>
              <td>C</td>
              <td>Eier</td>
            </tr>
            <tr>
              <td>D</td>
              <td>Fisch</td>
            </tr>
            <tr>
              <td>E</td>
              <td>Erdnüsse</td>
            </tr>
            <tr>
              <td>F</td>
              <td>Soja</td>
            </tr>
            <tr>
              <td>G</td>
              <td>Milch</td>
            </tr>
            <tr>
              <td>H</td>
              <td>Schalenfrüchte</td>
            </tr>
            <tr>
              <td>T</td>
              <td>Sellerie</td>
            </tr>
            <tr>
              <td>J</td>
              <td>Senf</td>
            </tr>
            <tr>
              <td>K</td>
              <td>Sesam</td>
            </tr>
            <tr>
              <td>L</td>
              <td>Schwefeldioxid</td>
            </tr>
            <tr>
              <td>M</td>
              <td>Lupine</td>
            </tr>
            <tr>
              <td>I</td>
              <td>Weichmacher</td>
            </tr>
          </tbody>
        </table>

        <table className="info_table">
          <thead>
            <tr>
              <th>Zusatzstoffe</th>
              <th>Bedeutung</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Konservierungsstoffe</td>
            </tr>
            <tr>
              <td>2</td>
              <td>Farbstoffe</td>
            </tr>
            <tr>
              <td>3</td>
              <td>Phosphate</td>
            </tr>
            <tr>
              <td>4</td>
              <td>Geschmacksverstärker</td>
            </tr>
            <tr>
              <td>5</td>
              <td>Antioxidationsmittel</td>
            </tr>
            <tr>
              <td>6</td>
              <td>Farbstoff Beta-Carotin</td>
            </tr>
            <tr>
              <td>7</td>
              <td>Milcheiweiß</td>
            </tr>
            <tr>
              <td>8</td>
              <td>Geschwärzt</td>
            </tr>
            <tr>
              <td>9</td>
              <td>Salatmayonnaise</td>
            </tr>
            <tr>
              <td>10</td>
              <td>Säuerungsmittel</td>
            </tr>
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="orange-button">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FoodlistModal;
