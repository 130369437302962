import React from "react";
import { TailSpin } from "react-loader-spinner";
import './LoadingSpinner.scss'

const LoadingSpinner = () => {
  return (
    <div className="loadingspinner_wrapper p-5">
      <TailSpin
        height="120"
        width="120"
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  );
};

export default LoadingSpinner;
