import React from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer id="footer__component">
      <nav className="footer-list col-lg-12 text-center">
        <Link to="/impressum">
          <li>Impressum</li>
        </Link>
        <a
          target="_blank"
          href="https://www.google.com/maps/place/D%C3%B6ner+%26+Pizza+Ecke/@51.0181743,13.7016733,17z/data=!3m2!4b1!5s0x4709c5a46fa75aa9:0xb712535611360f87!4m6!3m5!1s0x4709c585a35fb737:0x5478ca92b22babad!8m2!3d51.0181709!4d13.7038673!16s%2Fg%2F11rbdrcz52?hl=de"
          rel="noreferrer"
        >
          <li>Windbergstraße 2 | 01189 | Dresden</li>
        </a>
        <Link to="/datenschutzerklaerung">
          <li>Datenschutzerklärung</li>
        </Link>
      </nav>
    </footer>
  );
};

export default Footer;
