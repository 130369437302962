import React from "react";

import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.scss";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import ErrorPage from "./components/Routes/ErrorPage";
import RootLayout from "./components/Routes/RootLayout";
import data from "./files/food.json";
import Foodlist from "./components/Routes/Speisekarte/Foodlist/Foodlist";
import Impressum from "./components/Routes/Impressum";
import Datenschutzerklaerung from "./components/Routes/Datenschutzerklaerung";
import ImageGallery from "./components/Routes/ImageGallery";

// import DataSecurityModal from "./components/Modals/DataSecurityModal";

const Home = React.lazy(() => import("./components/Routes/Home"));

const Speisekarte = React.lazy(() =>
  import("./components/Routes/Speisekarte/Speisekarte")
);

const food = data.food;
const catData = food.categories;
const foodItems = food.items;

// console.log(doenerData);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />} errorElement={<ErrorPage />}>
      <Route index element={<Home />} />
      <Route path="speisekarte" element={<Speisekarte data={catData} />} />
      <Route
        path="speisekarte/doener"
        element={<Foodlist data={foodItems.doener} />}
      />
      <Route
        path="speisekarte/durum"
        element={<Foodlist data={foodItems.durum} />}
      />
      <Route
        path="speisekarte/doenerteller"
        element={<Foodlist data={foodItems.doenerteller} />}
      />
      <Route
        path="speisekarte/lamacun"
        element={<Foodlist data={foodItems.lamacun} />}
      />
      <Route
        path="speisekarte/boerek"
        element={<Foodlist data={foodItems.pide_borek} />}
      />
      <Route
        path="speisekarte/spaghetti"
        element={<Foodlist data={foodItems.spaghetti} />}
      />
      <Route
        path="speisekarte/auflauf"
        element={<Foodlist data={foodItems.auflauf} />}
      />
      <Route
        path="speisekarte/schnitzel"
        element={<Foodlist data={foodItems.schnitzel} />}
      />
      <Route
        path="speisekarte/vegetary"
        element={<Foodlist data={foodItems.vegetarisch} />}
      />
      <Route
        path="speisekarte/salads"
        element={<Foodlist data={foodItems.Salate} />}
      />
      <Route
        path="speisekarte/pizza"
        element={<Foodlist data={foodItems.Pizza} />}
      />
      <Route
        path="speisekarte/burger"
        element={<Foodlist data={foodItems.Burger} />}
      />
      <Route path="/impressum" element={<Impressum />} />
      <Route
        path="/datenschutzerklaerung"
        element={<Datenschutzerklaerung />}
      />
      <Route path="/gallery" element={<ImageGallery />} />
    </Route>
  )
);

function App() {
  const [modalShow, setModalShow] = React.useState(true);

  return (
    <div className="App">
      {/* <DataSecurityModal show={modalShow} onHide={() => setModalShow(false)} /> */}
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
