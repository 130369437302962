import "./Impressum.scss";

const Impressum = () => {
  return (
    <div className="impressum__wrapper max-width-600" style={{ padding: "50px" }}>
      <h2>Impressum</h2>

      <h3>Angaben gemäß § 5 TMG:</h3>

      <p>Döner Ecke Dresden Windbergstraße 2 01189 Dresden</p>

      <h3>Vertreten durch:</h3>
      <p>Ali Lale</p>

      <h3>Kontakt:</h3>
      <p>Telefon: 0351 / 12345678 E-Mail: alilale10101991@hotmail.com</p>

      <h3>Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz:</h3>
      <p>203/243/01764</p>

      <h3>Aufsichtsbehörde:</h3>
      <p>Gewerbeamt Dresden Postfach 12 00 20 01001 Dresden</p>

      <p>
        Plattform der Europäischen Kommission zur Online-Streitbeilegung:
        https://ec.europa.eu/odr
      </p>

      <h3>Haftungsausschluss:</h3>
      <p>
        Wir haben die Inhalte unserer Seiten mit größter Sorgfalt erstellt. Für
        die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir
        jedoch keine Gewähr übernehmen. Als Dienstanbieter sind wir gemäß § 7
        Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen
        Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
        Dienstanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte
        fremde Informationen zu überwachen oder nach Umständen zu forschen, die
        auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur
        Entfernung oder Sperrung der Nutzung von Informationen nach den
        allgemeinen Gesetzen bleiben hiervon unberührt.
      </p>
    </div>
  );
};

export default Impressum;
