import React, { useState, useEffect } from "react";
import "./Foodlist.scss";
import "../../../../Styles/all-styles.scss";
import Button from "react-bootstrap/Button";
import FoodlistModal from "../../../Modals/FoodlistModal";

const Foodlist = (props) => {
  const [isDoublePriced, setIsDoublePriced] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);

  const data = props.data;

  useEffect(() => {
    window.scrollTo(0, 0); // scroll to top
  }, []);

  const items = data.list.map((item) => {
    if (item.price_s) {
      !isDoublePriced && setIsDoublePriced(true);
      return (
        <div className="food_item" key={Math.random() + item.name}>
          <span>{item.id} - {item.name}</span>
          <span>{item.add}</span>
          <span className="food_item__price1">{item.price_s.toFixed(2)} €</span>
          <span className="food_item__price2">
            {item.price_xl.toFixed(2)} €
          </span>
          <div>
            {item.desc} <span>{item.Allergene.join(", ")}</span>
          </div>
        </div>
      );
    } else {
      return (
        <div className="food_item" key={Math.random() + item.name}>
          <span>
            <span className="food_item_number">{item.id} - </span> {item.name}
          </span>
          <span>{item.add}</span>
          <span>{item.price.toFixed(2)} €</span>
          <div>
            {item.desc} <span>{item.Allergene.join(", ")}</span>
          </div>
        </div>
      );
    }
  });

  return (
    <div className="food_container">
      <FoodlistModal show={modalShow} onHide={() => setModalShow(false)} />
      <div
        className="irgendwas-item"
        style={{ backgroundImage: `url(${data.img})` }}
      ></div>

      <div className="separator"></div>

      {isDoublePriced && (
        <div className="header_item">
          <span>klein</span>
          <span>groß</span>
        </div>
      )}
      {items}
      <div className="separator"></div>
      <Button
        variant="primary"
        onClick={() => setModalShow(true)}
        className="orange-button"
      >
        <i className="bi bi-info-circle allergene-info-icon" sx="margin-b"></i>
        <span>Allergene Anzeigen</span>
      </Button>
    </div>
  );
};

export default Foodlist;
